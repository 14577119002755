import { fetchProfile, fetchNoticeList, fetchFarmerList } from "@/api/index";
import { fetchUserGrowthList, fetchGrowthList } from "@/api/growth";
import { fetchDeviceTrendKey } from "@/api/device";
import { fetchUserFarmList, fetchWeather } from "@/api/farm";
import { fetchDeviceInfoForrn400, fetchDeviceInfoForrn171 } from "@/api/device";

const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit }, data) {
    commit("LOGIN", data);
    // commit("SET_USER_INFO", data);
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    commit("LOGOUT");
    window.location.href = "/login";
  },

  /**
   * fetch User 정보
   */
  getAccountInfo({ commit, dispatch }) {
    fetchProfile().then((res) => {
      if (res.data.status == 200 && res.data.data) {
        if (res.data.data.userType == "EXAMINER") {
          dispatch("getFarmerList");
        } else {
          dispatch("getUserFarmList", res.data.data._id);
        }
        let data = {
          userId: res.data.data._id,
          isAdmin: res.data.data.isAdmin,
          mobileNo: res.data.data.mobileNo,
          username: res.data.data.username,
          userType: res.data.data.userType,
          appAlarm: res.data.data.appAlarm,
        };

        commit("SET_USER_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },
  getUserFarmList({ commit, dispatch }, id) {
    let params = {
      farmerId: id,
    };
    fetchUserFarmList(params).then((res) => {
      if (res.data.status == 200) {
        let data = res.data.data;
        commit("SET_USER_FARMLIST", data);
        commit("SET_USER_MAINFARM", data[0]);
        dispatch("getWeather", data[0]);
        dispatch("getDeviceInfo", data[0]);
      } else {
        let message = res.data.message;
        alert(message);
      }
    });
  },
  getFarmerList({ commit }) {
    fetchFarmerList().then((res) => {
      if (res.data.status == 200) {
        let data = res.data.data;
        commit("SET_FARMERLIST", data);
      } else {
        let message = res.data.message;
        alert(message);
      }
    });
  },
  getWeather({ commit }, data) {
    if (data == undefined) {
      commit("SET_WEATHER", data);
      return;
    } else {
      commit("SET_LOADING", true);
      let xy = {
        x: data.nx,
        y: data.ny,
      };
      fetchWeather(xy).then((res) => {
        if (res.data.status == 200) {
          let data = res.data.data;
          commit("SET_WEATHER", data);
          commit("SET_WEATHER_ERR", false);
        } else {
          commit("SET_WEATHER_ERR", true);
        }
        commit("SET_LOADING", false);
      });
    }
  },
  async getDeviceInfo({ commit }, data) {
    if (data == null) {
      commit("SET_DEVICEINFO", data);
      return;
    } else {
      let farm = {
        farm: data._id,
      };
      let results = {};
      await fetchDeviceInfoForrn400(farm).then((res) => {
        if (res.data.status == 200 && res.data.data.sensors) {
          results.sensors = res.data.data.sensors[0];
        }
      });
      await fetchDeviceInfoForrn171(farm).then((res) => {
        if (res.data.status == 200 && res.data.data.sensors) {
          results.co2Sensors = res.data.data.sensors[0];
        }
      });
      commit("SET_DEVICEINFO", results);
    }
  },

  getUserGrowthList({ commit }) {
    fetchUserGrowthList().then((res) => {
      if (res.data.status == 200) {
        let data = res.data.data;
        commit("SET_USER_GROWTHLIST", data);
      } else {
        let message = res.data.message;
        alert(message);
      }
    });
  },
  getGrowthList({ commit }, data) {
    let query = {
      farmer: data.farmer,
      page: data.page,
    };
    fetchGrowthList(query).then((res) => {
      if (res.data.status == 200) {
        let result = {
          list: res.data,
          remainder: data.remainder,
        };

        commit("SET_USER_GROWTHLIST", result);
      } else {
        let message = res.data.message;
        alert(message);
      }
    });
  },
  getNotice({ commit }, data) {
    let params = {
      page: data.page,
    };
    fetchNoticeList(params).then((res) => {
      if (res.data.status == 200) {
        let data = res.data;
        commit("SET_NOTICELIST", data);
      } else {
        let message = res.data.message;
        alert(message);
      }
    });
  },
  getDeviceTrendKey({ commit }, data) {
    let params = {
      farm: data.farm,
      farmer: data.farmer,
    };
    fetchDeviceTrendKey(params).then((res) => {
      if (res.status == 200) {
        let data = res.data.data;
        commit("SET_TREND_KEY", data);
      }
    });
  },
  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_NAVBAR_VISIBLE({ commit }, data) {
    commit("SET_NAVBAR_VISIBLE", data);
  },
  SET_MENU_VISIBLE({ commit }, data) {
    commit("SET_MENU_VISIBLE", data);
  },
  SET_PAGE_NAME({ commit }, data) {
    commit("SET_PAGE_NAME", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
};

export default actions;
